/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:9918e585-f4ee-4a20-a187-532508c44837",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_rDlRcsuWO",
    "aws_user_pools_web_client_id": "69qo9ulgb4kcksncv8kgi4hggv",
    "oauth": {
        "domain": "whatstheirfaceapp88ca656f-88ca656f-master.auth.ap-southeast-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:19006/,https://whats-their-face.com/,https://www.whats-their-face.com/",
        "redirectSignOut": "https://www.whats-their-face.com/signout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
