import React from 'react';
import { Text, View, Button } from 'react-native';
//https://github.com/ykbryan/aws-amplify-react-native-auth-facebook-google
//https://github.com/joannebester/aws-amplify/blob/master/media/authentication_guide.md#2-withauthenticator-hoc
// @ts-ignore
import { withFacebook } from 'aws-amplify-react';
import { Auth } from 'aws-amplify'

import Amplify from '@aws-amplify/core'
import amplifyConfig from './aws-exports'

const configureAmplify = () => {

  let configUpdate: any = {...amplifyConfig};

  var urlsIn = amplifyConfig.oauth.redirectSignIn.split(",");
  var urlsOut = amplifyConfig.oauth.redirectSignOut.split(",");
  const oauth = {...amplifyConfig.oauth};
  const hasLocalhost = (hostname: string) => Boolean(hostname.match(/localhost/) || hostname.match(/127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}/));
  const hasHostname = (hostname: string) => Boolean(hostname.includes(window.location.hostname));
  const isLocalhost = hasLocalhost(window.location.hostname);
  const isHostname = hasHostname(window.location.hostname);
  //do filter
  if (isLocalhost) {
    urlsIn.forEach((e) => { if (hasLocalhost(e)) { oauth.redirectSignIn = e; } });
    urlsOut.forEach((e) => { if (hasLocalhost(e)) { oauth.redirectSignOut = e; } });
  }
  else if(isHostname) {
    urlsIn.forEach((e) => { if (hasHostname(e)) { oauth.redirectSignIn = e; } });
    urlsOut.forEach((e) => { if (hasHostname(e)) { oauth.redirectSignOut = e; } });
  }
  else {
    urlsIn.forEach((e) => { oauth.redirectSignIn = e; });
    urlsOut.forEach((e) => { oauth.redirectSignOut = e; });
  }

  configUpdate.oauth = oauth;
  Amplify.configure(configUpdate);
}
configureAmplify();

declare global {
  interface Window {
    FB: any,
    fbAsyncInit: any,
  }
}

interface AppProps {

}

interface AppState {
  AuthState: AuthStates
}

enum AuthStates {
  Authenticated,
  Authenticating,
  NotAuthenticated
}

export default class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      AuthState: AuthStates.NotAuthenticated
    };
  }

  // componentDidMount() {
  //   this.loadFacebookSDK();
  // }

  // loadFacebookSDK() {
  //   window.fbAsyncInit = function() {
  //     window.FB.init({
  //       appId            : config.facebook_app_id,
  //       autoLogAppEvents : true,
  //       xfbml            : true,
  //       version          : 'v3.1'
  //     });
  //   };

  //   (function(d, s, id){
  //      var js, fjs = d.getElementsByTagName(s)[0];
  //      if (d.getElementById(id)) {return;}
  //      js = d.createElement(s); js.id = id;
  //      js.src = "https://connect.facebook.net/en_US/sdk.js";
  //      fjs.parentNode.insertBefore(js, fjs);
  //    }(document, 'script', 'facebook-jssdk'));
  // }

  handleAuthStateChange(state: any) {
    console.log(state);
    if (state === 'signedIn') {
      this.setState({ AuthState: AuthStates.Authenticated });
    }
  }

  Buttons = (props: any) => (
    <View>
      {/* </View><FacebookButton> */}
      <Button onPress={props.facebookSignIn} title="Login with Facebook">
      </Button>
    </View>
  )

  Federated = withFacebook(this.Buttons);

  render() {
    return (
      this.state.AuthState === AuthStates.Authenticated ? (
        <View>
          <Text>Open up App.js to start working on your app!</Text>
          <Text>Yo!</Text>
        </View>) :
        (
          this.state.AuthState === AuthStates.Authenticating ? (
            <View>Loader goes here</View>
          ) : (
              <View>
                <Button
                  title="Sign in with Facebook"
                  onPress={() => Auth.federatedSignIn({ provider: "Facebook" })}
                />
              </View>
            )
        )

    );
  }
}
